<template>
  <div class="questions">
    <div class="container padding-top-90">
      <div class="row">
        <div class="col-12">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Namn</th>
                <th>Fråga</th>
                <th>Inskickad</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="questions.length == 0">
                <td colspan="3" class="text-center">Inga frågor inskickade. :(</td>
              </tr>
              <tr :class="{ 'text-strikethrough': question.done }" v-for="question in questions" :key="question.id">
                <td class="align-middle">{{ question.name }}</td>
                <td class="align-middle">{{ question.question }}</td>
                <td class="align-middle">{{ $DateTime.fromJSDate(question.created.toDate(), { locale: "sv-SE" }).toLocaleString($DateTime.DATETIME_SHORT_WITH_SECONDS) }}</td>
                <td class="align-middle text-right">
                  <button type="button" class="btn btn-sm btn-outline-dark" @click="toggleQuestionDone(question)" v-if="question.done">Un-mark</button>
                  <button type="button" class="btn btn-sm btn-outline-success" @click="toggleQuestionDone(question)" v-else>Mark done</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase"

  export default {
    name: "questions",
    data() {
      return {
        questions: []
      }
    },
    methods: {
      async toggleQuestionDone(question) {
        let questionRef = await firebase.firestore().collection(`questions`).doc(question.id)
        questionRef.update({ done: !question.done })
      }
    },
    async mounted() {
      await this.$bind("questions", firebase.firestore().collection(`questions`).orderBy("created", "asc"))
    }
  }
</script>

<style lang="scss" scoped>
  .text-strikethrough { text-decoration: line-through; }
</style>